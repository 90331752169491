.Dashboard {
    display: flex;
    justify-content: space-between;

    .g2-html-annotation {
        top: 63px !important;
    }

    .RecentlySoldPackages {
        display: flex;
        flex-wrap: wrap;
    }

    &-LeftArea {
        width: 710px;
        display: flex;
        flex-wrap: wrap;
        margin: 10px;
        align-content: flex-start;

        &-DashboardCard {
            &-Style {
                height: auto;
                width: 100%;
                margin-bottom: 10px;
                display: flex;
                flex-wrap: wrap;
            }

            &-Card {
                &-1 {
                    width: 300px;
                    height: 172px;
                    border-radius: 16px;
                    background-color: #fe69b5;
                    margin-right: 30px;
                    margin-bottom: 30px;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    &-Icon {
                        width: 55px;
                        object-fit: contain;
                        color: #ffffff;
                        position: absolute;
                        margin-left: 230px;
                    }

                    &-Number {
                        font-family: "Nunito", sans-serif;
                        font-size: 32px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.34;
                        letter-spacing: normal;
                        text-align: left;
                        color: #ffffff;
                        margin: 7px 0px;
                    }

                    &-BigTitle {
                        font-family: "Nunito", sans-serif;
                        font-size: 24px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.33;
                        letter-spacing: normal;
                        text-align: left;
                        color: #ffffff;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.36;
                        letter-spacing: normal;
                        text-align: left;
                        color: #ffffff;
                        margin-bottom: 30px;
                    }
                }

                &-2 {
                    width: 300px;
                    height: 172px;
                    border-radius: 16px;
                    background-color: #447ff4;
                    margin-bottom: 30px;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    &-Icon {
                        width: 55px;
                        object-fit: contain;
                        color: #ffffff;
                        position: absolute;
                        margin-left: 230px;
                    }

                    &-Number {
                        font-family: "Nunito", sans-serif;
                        font-size: 32px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.34;
                        letter-spacing: normal;
                        text-align: left;
                        color: #ffffff;
                        margin: 7px 0px;
                    }

                    &-BigTitle {
                        font-family: "Nunito", sans-serif;
                        font-size: 24px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.33;
                        letter-spacing: normal;
                        text-align: left;
                        color: #ffffff;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.36;
                        letter-spacing: normal;
                        text-align: left;
                        color: #ffffff;
                        margin-bottom: 30px;
                    }
                }

                &-3 {
                    width: 300px;
                    height: 172px;
                    border-radius: 16px;
                    background-color: #ffffff;
                    margin-right: 30px;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    &-Icon {
                        width: 55px;
                        object-fit: contain;
                        color: #ffffff;
                        position: absolute;
                        margin-left: 230px;
                    }

                    &-Number {
                        font-family: "Nunito", sans-serif;
                        font-size: 32px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.34;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }

                    &-BigTitle {
                        font-family: "Nunito", sans-serif;
                        font-size: 24px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.33;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.36;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }
                }

                &-4 {
                    width: 300px;
                    height: 172px;
                    border-radius: 16px;
                    background-color: #ffffff;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    &-Icon {
                        width: 55px;
                        object-fit: contain;
                        color: #ffffff;
                        position: absolute;
                        margin-left: 230px;
                    }

                    &-Number {
                        font-family: "Nunito", sans-serif;
                        font-size: 32px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.34;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }

                    &-BigTitle {
                        font-family: "Nunito", sans-serif;
                        font-size: 24px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.33;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.36;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }
                }
            }
        }


        &-ActiveUsers {
            padding: 20px;
            width: 100%;
            background-color: #fff;
            margin: 20px 0px;
            border-radius: 16px;

            &-GraficArea {
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: start;
                margin-bottom: 20px;

                &-ActiveStudents {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    &-Grafic {
                        width: 136px;
                        height: 136px;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.38;
                        letter-spacing: normal;
                        text-align: center;
                        color: #a8aabc;
                    }
                }


                &-InactiveStudents {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    &-Grafic {
                        width: 136px;
                        height: 136px;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.38;
                        letter-spacing: normal;
                        text-align: center;
                        color: #a8aabc;
                    }
                }

                &-ActiveTeachers {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    &-Grafic {
                        width: 136px;
                        height: 136px;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.38;
                        letter-spacing: normal;
                        text-align: center;
                        color: #a8aabc;
                    }
                }
            }

            &-Title {
                font-family: "Nunito", sans-serif;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                color: #434655;
            }
        }

        &-QuestionsAsked {
            padding: 20px;
            width: 100%;
            background-color: #fff;
            border-radius: 16px;

            &-Title {
                font-family: "Nunito", sans-serif;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                color: #434655;
            }

            &-Information {
                display: flex;
                align-items: center;
                margin: 20px 0px;

                &-Student {
                    display: flex;
                    align-items: center;
                    margin-right: 130px;

                    &-Icon {
                        width: 52px;
                        height: 52px;
                        background-color: #f4f444;
                        border-radius: 100px;
                    }

                    &-Text {
                        display: grid;
                        padding-left: 20px;


                        &-Title {
                            font-family: "Nunito", sans-serif;
                            font-size: 12px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.33;
                            letter-spacing: normal;
                            text-align: left;
                            color: #a8aabc;
                        }

                        &-Number {
                            font-family: "Nunito", sans-serif;
                            font-size: 24px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.33;
                            letter-spacing: normal;
                            text-align: left;
                            color: #434655;
                        }
                    }
                }
            }
        }
    }



    &-RightArea {
        width: 710px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;


        &-Graphic {
            width: 100%;
            height: auto;
            background-color: #fff;
            padding: 20px;
            margin-bottom: 20px;
            border-radius: 16px;

            &-Information {
                display: flex;
                align-items: center;
                margin: 20px 0px;

                &-Student {
                    display: flex;
                    align-items: center;
                    margin-right: 130px;

                    &-Icon {
                        width: 52px;
                        height: 52px;
                        background-color: #447ff4;
                        border-radius: 100px;
                    }

                    &-Text {
                        display: grid;
                        padding-left: 20px;


                        &-Title {
                            font-family: "Nunito", sans-serif;
                            font-size: 12px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.33;
                            letter-spacing: normal;
                            text-align: left;
                            color: #a8aabc;
                        }

                        &-Number {
                            font-family: "Nunito", sans-serif;
                            font-size: 24px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.33;
                            letter-spacing: normal;
                            text-align: left;
                            color: #434655;
                        }
                    }
                }

                &-Teacher {
                    display: flex;
                    align-items: center;

                    &-Icon {
                        width: 52px;
                        height: 52px;
                        background-color: #fe69b5;
                        border-radius: 100px;
                    }

                    &-Text {
                        display: grid;
                        padding-left: 20px;

                        &-Title {
                            font-family: "Nunito", sans-serif;
                            font-size: 12px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.33;
                            letter-spacing: normal;
                            text-align: left;
                            color: #a8aabc;
                        }

                        &-Number {
                            font-family: "Nunito", sans-serif;
                            font-size: 24px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.33;
                            letter-spacing: normal;
                            text-align: left;
                            color: #434655;
                        }
                    }
                }
            }
        }

        &-Lists {
            width: 100%;
            height: 806px;
            display: flex;

            &-PaymentRequests {
                width: 100%;
                border-radius: 16px;
                background-color: #fff;
                padding: 20px;
                margin-right: 10px;
                display: flex;
                flex-wrap: wrap;


                .ant-table-row-level-0 {
                    height: 70px !important;
                }

                &-Title {
                    margin: 0px;
                    height: 15px;

                    &-Text {
                        font-family: "Nunito", sans-serif;
                        font-size: 19px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.37;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                        margin: 0px;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.38;
                        letter-spacing: normal;
                        text-align: left;
                        color: #a8aabc;
                        margin: 0px;
                    }
                }

                &-MoreArea {
                    width: 100%;
                    justify-content: center;
                    display: flex;
                    align-items: center;


                    &-More {
                        border-radius: 16px;
                        background-color: #c2d5f9;
                        text-decoration: none;
                        border: none;
                        padding: 20px 70px;


                        &-Text {
                            font-family: "Nunito", sans-serif;
                            font-size: 16px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.38;
                            letter-spacing: normal;
                            text-align: left;
                            color: #447ff4;
                        }
                    }
                }
            }


            &-RecentlySoldPackages {
                width: 100%;
                border-radius: 16px;
                background-color: #fff;
                padding: 20px;
                margin-left: 10px;
                display: flex;
                flex-wrap: wrap;


                &-Title {
                    margin: 0px;
                    height: 15px;

                    &-Text {
                        font-family: "Nunito", sans-serif;
                        font-size: 19px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.37;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }

                    &-Explanation {
                        font-family: "Nunito", sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.38;
                        letter-spacing: normal;
                        text-align: left;
                        color: #a8aabc;

                        &-Small {
                            font-family: "Nunito", sans-serif;
                            font-size: 12px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.38;
                            letter-spacing: normal;
                            text-align: left;
                            color: #a8aabc;
                        }
                    }

                }

                &-Icon {
                    color: #447ff4;
                }


                &-MoreArea {
                    width: 100%;
                    justify-content: center;
                    display: flex;
                    align-items: center;

                    &-More {
                        border-radius: 16px;
                        background-color: #c2d5f9;
                        text-decoration: none;
                        border: none;
                        padding: 20px 70px;

                        &-Text {
                            font-family: "Nunito", sans-serif;
                            font-size: 16px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.38;
                            letter-spacing: normal;
                            text-align: left;
                            color: #447ff4;
                        }
                    }
                }
            }
        }
    }

    .ant-table-row {
        background-color: #ffffff !important;
    }

}