.InsStudentAdd {
    display: flex;
    align-items: center;




    label {
        width: 131px;
        height: 19px;
        font-family: "Nunito", sans-serif;
        font-size: 14px;
        font-weight: 600px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        color: #434655;
        margin: 4px 30px 4px 24px;
        text-align: left;

    }





    .anticon {
        svg {
            width: 24px !important;
            height: 24px !important;
        }
    }


    &-Input {
        width: 409px;
        height: 42px;
        background-color: #ffffff;
        border: solid 1px rgba(168, 170, 188, 0.2);
        border-radius: 5px;
    }

    &-Button {
        margin: 8px 400px 384px 200px;

        &-Style {
            width: 409px;
            height: 55px;
            border-radius: 10px;
            background-color: #447ff4;
            color: #ffffff;
            padding: 17px 165px 16px;
            font-size: 16px;
            font-family: "Nunito", sans-serif;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-decoration: none;

        }
    }
}