.InsStudents {
    &-Header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-AddStudent {
            font-family: "Nunito", sans-serif;
            width: 146px;
            height: 39px;
            padding: 9.5px 25px;
            border-radius: 10px;
            background-color: #447ff4;
            color: #ffffff;
            text-decoration: none;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;

        }

        &-Search {

            width: 205px;
            height: 38px;
            border-radius: 10px;

            input,
            button {
                height: 38px !important;
                border: none;
            }

            border-radius: 10px;
            border: solid 1px rgba(168, 170, 188, 0.2);


        }

    }

    &-StudentList {
        width: auto;
        margin: 19px 0 0 0;
        padding: 15px 16px 24px 18px;
        border-radius: 10px;
        background-color: #ffffff;
        border: solid 1px rgba(168, 170, 188, 0.2);
    }



}