.Main {
    &-Dashboard {

        display: flex;
        justify-content: space-between;

        &-Card {
            width: 21%;
            border-radius: 10px;
            padding: 18px 16px 24px 16px;

            &-1 {
                background-color: #fff5e0;
            }

            &-2 {
                background-color: #efe6f6;
            }

            &-3 {
                background-color: #e0f8ea;
            }

            &-4 {
                background-color: #fceae4;
            }


            &-Icon {
                height: 24px;
                width: 24px;
                margin-bottom: 32px;
                border-radius: 100%;
                padding: 8px;
                display: flex;
                justify-content: center;
                align-items: center;

                &>* {
                    width: 24px;
                    height: 24px;
                }

                &-1 {

                    color: #ffa400;
                    background-color: #ffc957;

                }

                &-2 {
                    background-color: #d6bfe9;
                    color: #7523b5;
                }

                &-3 {
                    background-color: #b3edcb;
                    color: #00c150;

                }

                &-4 {
                    background-color: #f6c7b7;
                    color: #e33c05;
                }

            }

            &-BigTitle {
                font-family: "Nunito", sans-serif;
                font-size: 24px;
                font-weight: bold;
                color: #434655;
                margin-bottom: 2px;
                line-height: 1.33;


            }

            &-SmallTitle {
                font-family: "Nunito", sans-serif;
                font-size: 16px;
                font-weight: normal;
                color: #797a82;
                margin-top: 2px;
                line-height: 1.38;
            }
        }

        &-Graphic {
            display: flex;
            justify-content: space-between;

            &-1 {
                background-color: #ffffff;
                width: 50%;
                border-radius: 10px;
                padding: 24px 28px 11px 18px;
                margin: 24px 22px 12px 0;

                &-Title {
                    margin: 2px 0px 20px 0;
                    height: 19px;
                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    font-style: normal;
                    line-height: 1.36;
                    color: #434655;
                }

                &-Select {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &-Style {
                        height: 24px;
                        border-radius: 5px;
                        border: solid 1px rgba(168, 170, 188, 0.2);
                        margin-bottom: 30px;

                    }
                }


            }

            &-2 {
                background-color: #ffffff;
                width: 50%;
                border-radius: 10px;
                padding: 22px 32px 13px 16px;
                margin: 24px 0 12px 0;

                &-Title {
                    margin: 2px 0 20px 3px;
                    height: 19px;
                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: 1.36;
                    color: #434655;
                }

                &-Select {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &-Style {
                        height: 24px;
                        border-radius: 5px;
                        border: solid 1px rgba(168, 170, 188, 0.2);
                        margin-bottom: 30px;

                    }
                }

                &-Style {
                    font-family: "Nunito", sans-serif;
                    font-size: 2px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    color: #a8aabc;
                }
            }
        }

        &-StudentList {
            background-color: #ffffff;

            height: auto;
            margin-top: 24px;
            padding: 20px 16px;
            border-radius: 10px;

            &-Title {
                font-family: "Nunito", sans-serif;
                font-size: 16px;
                font-weight: bold;
                font-style: normal;
                letter-spacing: normal;
                line-height: 1.38;
                color: #434655;


            }
        }
    }
}