.InsSideBar {
    background-color: #447ff4;
    position: fixed;
    width: 240px;
    height: 100vh;
    padding: 16px 24px;
    z-index: 9999;


    &-StudiaLogo {
        font-family: "Nunito", sans-serif;
        font-weight: 900;
        color: #ffffff;
        font-size: 24px;
        line-height: 1.33;
        letter-spacing: normal;
    }

    &-KurumsalLogo {
        font-family: "Nunito", sans-serif;
        color: rgba(0, 0, 0, 0.6);
        ;
        font-size: 24px;
        line-height: 1.33;
        letter-spacing: normal;
    }

    &-SchoolName {
        font-family: "Nunito", sans-serif;
        font-weight: 600;
        color: #ffffff;
        font-size: 16px;
        line-height: 1.38;
        padding: 0px;
        opacity: 0.8;
        letter-spacing: normal;
    }

    &-Nav {

        margin-top: 30px;

        &-Link {
            font-family: "Nunito", sans-serif;
            display: flex;
            padding: 16px;
            justify-content: start;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            color: #ffffff;
            text-decoration: none;

            &.active {
                background-color: #3665c3;
                border-radius: 15px;

            }

            &-Icon {
                width: 24px;
                height: 24px;
                margin-right: 8px;

            }
        }

    }
}