.StudentDetail {

    &-Main {

        &-FirstArea {
            display: flex;
            justify-content: space-between;
            align-items: stretch;

            &-Center {
                display: grid;
                justify-content: center;
                align-items: center;

            }

            &-StudentInformation {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                width: 49%;

                &-info {
                    padding: 15px;
                    border-radius: 10px;
                    border: solid 1px rgba(168, 170, 188, 0.2);
                    background-color: #ffffff;
                    height: 64%;
                }


                &-Picture {
                    width: 100px;
                    height: 100px;
                    border-radius: 100px;
                    margin-left: 5px;
                    margin-top: 15px;
                }


                &-NameSurname {
                    height: 19px;
                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.36;
                    letter-spacing: normal;
                    text-align: left;
                    color: #434655;
                    margin-top: 8px;
                }

                &-RemainingQuestion {

                    height: 18px;
                    font-family: "Nunito", sans-serif;
                    font-size: 13px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    text-align: left;
                    color: #447ff4;

                    &-Icon {
                        color: #447ff4;
                        width: 12px;
                        height: 12px;
                        padding: 2px;
                        margin: 4px;
                        object-fit: contain;
                        border-style: dashed;
                        border-color: #447ff4;
                        border-radius: 100px;
                        border-width: 1px;

                    }

                    &-Center {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 15px;
                    }


                }


                &-Form {

                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 16px;

                    &-Title {
                        height: 15px;
                        font-family: "Nunito", sans-serif;
                        font-size: 11px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.36;
                        letter-spacing: normal;
                        text-align: left;
                        color: #a8aabc;
                    }

                    &-Text {
                        height: 18px;
                        font-family: "Nunito", sans-serif;
                        font-size: 13px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.38;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }

                    &-Input {
                        width: 235px;
                        height: 40px;
                        padding: 9px 0px 10px 7px;
                        border-radius: 7.5px;
                        border: solid 1px rgba(168, 170, 188, 0.2);
                        background-color: rgba(255, 255, 255, 0);

                    }
                }



                &-DeleteButton {
                    width: 235px;
                    height: 55px;
                    padding: 18px 34px;
                    border-radius: 7.5px;
                    border: solid 1px #f00;
                    background-color: #ffffff;
                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.36;
                    letter-spacing: normal;
                    text-align: left;
                    color: #ff4040;
                }
            }




            &-Grafic {
                width: 49%;
                border-radius: 10px;

                &-1 {
                    padding: 20px;
                    border-radius: 10px;
                    border: solid 1px rgba(168, 170, 188, 0.2);
                    background-color: #fff;
                    margin-bottom: 16px;


                    &-Text {
                        margin: 2px 10px 10px 0;
                        height: 19px;
                        font-family: "Nunito", sans-serif;
                        font-size: 14px;
                        font-weight: bold;
                        font-style: normal;
                        line-height: 1.36;
                        color: #434655;
                    }

                    &-Select {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &-Style {
                            height: 24px;
                            border-radius: 5px;
                            border: solid 1px rgba(168, 170, 188, 0.2);
                            margin-bottom: 5px;

                        }
                    }
                }

                &-2 {
                    padding: 20px;
                    border-radius: 10px;
                    border: solid 1px rgba(168, 170, 188, 0.2);
                    background-color: #fff;


                    &-Text {
                        margin: 2px 10px 10px 0;
                        height: 19px;
                        font-family: "Nunito", sans-serif;
                        font-size: 14px;
                        font-weight: bold;
                        font-style: normal;
                        line-height: 1.36;
                        color: #434655;

                    }

                    &-Select {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &-Style {
                            height: 24px;
                            border-radius: 5px;
                            border: solid 1px rgba(168, 170, 188, 0.2);
                            margin-bottom: 30px;

                        }
                    }
                }


            }

        }


        &-SecondArea {
            padding-bottom: 25px;

            &-Grafic {
                display: flex;
                justify-content: space-between;
                margin-top: 16px;


                &-1 {
                    padding: 20px;
                    border-radius: 10px;
                    border: solid 1px rgba(168, 170, 188, 0.2);
                    background-color: #ffffff;


                    &-Text {
                        margin: 2px 10px 10px 0;
                        height: 19px;
                        font-family: "Nunito", sans-serif;
                        font-size: 14px;
                        font-weight: bold;
                        font-style: normal;
                        line-height: 1.36;
                        color: #434655;
                    }

                    &-Select {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &-Style {
                            height: 24px;
                            border-radius: 5px;
                            border: solid 1px rgba(168, 170, 188, 0.2);


                        }
                    }
                }

                &-2 {
                    padding: 20px;
                    border-radius: 10px;
                    border: solid 1px rgba(168, 170, 188, 0.2);
                    background-color: #ffffff;
                    margin-top: 16px;

                    &-Text {
                        margin: 2px 10px 10px 0;
                        height: 19px;
                        font-family: "Nunito", sans-serif;
                        font-size: 14px;
                        font-weight: bold;
                        font-style: normal;
                        line-height: 1.36;
                        color: #434655;
                    }

                    &-Select {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &-Style {
                            height: 24px;
                            border-radius: 5px;
                            border: solid 1px rgba(168, 170, 188, 0.2);

                        }
                    }
                }
            }
        }

    }
}