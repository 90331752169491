.QuestionsDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-FirstArea {
        display: flex;

        &-Style {

            width: 565px;
            height: 375px;
            margin: 20px 10px 7px 50px;
            padding: 39px 152px 20px 19px;
            border-radius: 24px;
            background-color: #ffffff;

        }

        &-Text {
            height: 32px;
            font-family: "Nunito", sans-serif;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #434655;
            margin-bottom: 10px;
        }


        &-Annotation {
            padding: 20px 152px 20px 0px;

            &-AnnotationTitle {
                height: 32px;
                font-family: "Nunito", sans-serif;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                color: #434655;
            }

            &-MessageTitle {
                height: 19px;
                font-family: "Roboto", sans-serif;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: normal;
                text-align: left;
                color: #023358;
            }

            &-Message {
                height: 19px;
                font-family: Roboto;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: normal;
                text-align: left;
                color: #a2a1bc;
                margin-bottom: 10px;
            }


        }
    }

    &-SecondArea {

        display: flex;
        justify-content: flex-start;
        align-items: center;

        &-Text {
            height: 32px;
            margin: 7px 530px 0px 76px;
            font-family: "Nunito", sans-serif;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: center;
            color: #434655;
        }

        &-Question {
            width: 542px;
            height: 362px;
            margin: 15px 49px 144px 73px;
            border-radius: 20px;
            border: solid 1px #707070;
        }

        &-Answer {
            width: 542px;
            height: 362px;
            margin: 15px 364px 144px 54px;
            border-radius: 20px;
            border: solid 1px #707070;

        }
    }

}