.InsStats {

    &-FirstArea {
        display: flex;
        justify-content: space-between;


        &-Grafic {
            display: flex;
            justify-content: space-between;
            text-align: center !important;
            height: 400px !important;
            width: 400px !important;

            &-1 {
                width: 49%;
                margin: 16px 15px 16px 6px;
                padding: 24px 29.8px 12px 16px;
                border-radius: 10px;
                border: solid 1px rgba(168, 170, 188, 0.2);
                background-color: #ffffff;

                &-Title {
                    margin: 2px 10px 30px 0;
                    height: 19px;
                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    font-style: normal;
                    line-height: 1.36;
                    color: #434655;
                }

                &-Select {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-align: center;

                    &-Style {
                        height: 24px;
                        border-radius: 5px;
                        border: solid 1px rgba(168, 170, 188, 0.2);
                        margin-bottom: 30px;
                    }
                }
            }

            &-2 {
                width: 48%;
                margin: 16px 6px 16px 0px;
                padding: 24px 29.8px 12px 16px;
                border-radius: 10px;
                border: solid 1px rgba(168, 170, 188, 0.2);
                background-color: #ffffff;
                position: relative;


                &-Title {
                    margin: 2px 119px 30px 0;
                    height: 19px;
                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    font-style: normal;
                    line-height: 1.36;
                    color: #434655;
                }


                &-Select {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-align: center;

                    &-Style {
                        height: 24px;
                        border-radius: 5px;
                        border: solid 1px rgba(168, 170, 188, 0.2);
                        margin-bottom: 30px;
                    }
                }
            }

        }
    }

    &-SecondArea {
        display: flex;
        justify-content: space-between;

        &-Grafic {
            display: flex;
            justify-content: space-between;
            text-align: center !important;
            height: 400px !important;
            width: 400px !important;

            &-1 {

                width: 49%;
                margin: 16px 15px 16px 6px;
                padding: 24px 29.8px 12px 16px;
                border-radius: 10px;
                border: solid 1px rgba(168, 170, 188, 0.2);
                background-color: #ffffff;

                &-Title {
                    margin: 2px 10px 30px 0;
                    height: 19px;
                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    font-style: normal;
                    line-height: 1.36;
                    color: #434655;
                }

                &-Select {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-align: center;

                    &-Style {
                        height: 24px;
                        border-radius: 5px;
                        border: solid 1px rgba(168, 170, 188, 0.2);
                        margin-bottom: 30px;
                    }
                }
            }

            &-2 {
                width: 48%;
                margin: 16px 6px 16px 0px;
                padding: 24px 29.8px 12px 16px;
                border-radius: 10px;
                border: solid 1px rgba(168, 170, 188, 0.2);
                background-color: #ffffff;
                position: relative;


                &-Title {
                    margin: 2px 119px 30px 0;
                    height: 19px;
                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    font-style: normal;
                    line-height: 1.36;
                    color: #434655;
                }


                &-Select {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-align: center;

                    &-Style {
                        height: 24px;
                        border-radius: 5px;
                        border: solid 1px rgba(168, 170, 188, 0.2);
                        margin-bottom: 30px;
                    }
                }
            }

        }
    }


    &-ThirdArea {
        display: flex;
        justify-content: space-between;

        &-ListsBody {
            width: 33.33%;
            height: auto;
            padding: 18px;
            background-color: #ffffff;
            border-radius: 10px;
            border: solid 1px rgba(168, 170, 188, 0.2);
            margin-left: 5px;
            margin-right: 5px;
        }

        &-ListTitles {
            height: 24px;
            color: #434655;
            font-family: "Nunito" sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: bold;
            line-height: 1.36;
        }

        &-Select {
            display: flex;
            justify-content: space-between;
            align-items: first baseline;
            text-align: center;

            &-Style {
                height: 24px;
                border-radius: 5px;
                border: solid 1px rgba(168, 170, 188, 0.2);

            }
        }
    }


    // .ant-table-thead tr th {
    //     text-align: left !important;
    // }




    .ant-table-thead {
        tr {
            th {
                background-color: transparent !important;
                text-align: center !important;
                font-family: "Nunito", sans-serif !important;
                font-size: 14px !important;
                font-stretch: normal !important;
                font-style: normal !important;
                line-height: 1.36 !important;
                letter-spacing: normal !important;
                color: #434655 !important;
                height: 40px !important;
                vertical-align: bottom !important;
            }
        }

    }


    .ant-table-column-sorters {
        text-align: left !important;
    }

    .ant-table-column-title {
        font-size: 13px !important;
        color: #a8aabc !important;
        text-align: left !important;
    }

    .select {
        font-family: Nunito;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #a8aabc;
    }


}



.ant-table-tbody {
    align-items: center !important;
    text-align: center !important;
    vertical-align: middle !important;
    background-color: #ffffff !important;
}