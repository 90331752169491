.Teachers {


    &-Input {
        position: relative;

        @media (max-width: 959px) {

            width: 100%;
        }

        &-Style {
            width: 315px;
            height: 59px;
            border-radius: 19.5px;
            border: solid 1px #eee;
            background-color: #ffffff;
            padding-left: 10px;
            padding-right: 30px;
            margin-bottom: 16px;

            @media (max-width: 959px) {
                width: 100%;
            }
        }

        &-SearchLogo {
            position: absolute;
            top: 15px;
            left: 280px;


            @media (max-width: 959px) {
                left: initial;
                right: 10px;
            }

            &-Style {
                width: 30px;
                height: 30px;
            }
        }
    }


    &-Application {
        display: flex;
        justify-content: space-between;
        padding-right: 100px;

        @media (max-width: 959px) {
            flex-wrap: wrap;
            padding-right: 0px;
            width: 100%;
            margin-bottom: 25px;
        }

        &-Button {
            width: 215px;
            height: 54px;
            border-radius: 16px;
            background-color: #447ff4;
            text-decoration: none;
            border: none;



            &-Text {
                font-family: "Nunito", sans-serif;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                color: #ffffff;

                &-Number {
                    padding: 1px 8px;
                    border-radius: 10px;
                    background-color: #ff3c3c;
                    font-family: "Nunito", sans-serif;
                    font-size: 12px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    text-align: left;
                    color: #ffffff;
                    margin-left: 5px;

                }
            }
        }
    }


    &-DetailButton {
        width: 215px;
        height: 54px;
        border-radius: 16px;
        background-color: #447ff4;
        text-decoration: none;
        border: none;



        &-Text {
            font-family: "Nunito", sans-serif;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
        }
    }

}

.ant-table-cell {
    align-items: center !important;
    text-align: center !important;
    vertical-align: middle !important;
    background-color: transparent !important;
}

.ant-table-row {
    background-color: transparent !important;
}


.ant-pagination-item {
    a {
        color: #447ff4 !important;
    }

    &-active {
        background-color: #447ff4 !important;

        a {
            color: #ffffff !important;
        }
    }
}

.ant-pagination-item-link {
    svg {
        color: #447ff4;
    }
}

.ant-table-thead {
    ::before {
        background-color: transparent !important;
    }
}

.ant-table-tbody {
    tr {
        td {
            border-bottom: 0px !important;

        }
    }
}