.Public {
    display: flex;
    flex-wrap: wrap;

    &-Card {
        align-items: center;
        text-align: center;


        &-Style {
            width: 266px;
            margin-right: 20px;
            margin-bottom: 40px;
            border-radius: 24px;
            background-color: #fff;
            padding: 10px;
        }

        &-Header {
            height: 24px;
            font-family: "Roboto", sans-serif;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: center;
            color: #023358;
            margin-bottom: 10px;
        }

        &-Price {
            height: 24px;
            font-family: "Roboto", sans-serif;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: center;
            color: #023358;
            margin-bottom: 10px;
        }

        &-Input {
            width: 191px;
            height: 45px;
            border-radius: 10px;
            border: solid 2px #023358;
            background-color: #fff;
            margin-bottom: 10px;

            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: normal;
            text-align: center;
            color: #023358;
        }

        &-Button {

            &-Style {
                width: 191px;
                height: 45px;
                border-radius: 10px;
                background-color: #45bf55;
                border: none;
            }

            &-Text {
                height: 21px;
                font-family: "Roboto", sans-serif;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.31;
                letter-spacing: normal;
                text-align: center;
                color: #fafafa;
            }
        }
    }
}