.Plans {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;

    .PlansCard {
        width: 32%;
        margin-bottom: 8px;


        &-Package {
            min-height: 100%;
            border-radius: 10px;
            display: flex;


            &-1 {
                padding: 16px;
                background-color: #ffffff;
                border-radius: 10px;
                border: solid 1px rgba(168, 170, 188, 0.2);
            }

            &-2 {
                padding: 16px;
                background-color: #ffffff;
                border-radius: 10px;
                border: solid 1px rgba(168, 170, 188, 0.2);
            }

            &-3 {
                padding: 16px;
                background-color: #ffffff;
                border-radius: 10px;
                border: solid 1px rgba(168, 170, 188, 0.2);
            }



            &-Image {
                width: 22px;
                height: 22px;
                margin: 0 7px 12.6px 0;
                object-fit: contain;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-Title {
                width: 125px;
                height: 32px;
                font-size: 24px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                font-family: "Nunito", sans-serif;
                color: #434655;
                margin: 12.6px 119px 16px 0;
            }

            &-Tick {
                height: 20px;
                width: 20px;
                border-radius: 100px;
                background-color: #447ff4;
                color: #ffffff;
                font-size: 5px !important;
                margin-right: 6px;
            }


            &-Text {
                display: flex;
                align-items: center;
                margin: 17px 29px 8px 7px;
                font-family: "Nunito", sans-serif;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: normal;
                text-align: left;
                color: #a8aabc;

            }

            &-Price {
                margin: 50px 0 0 10px;
                font-family: "Nunito", sans-serif;
                font-size: 24px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.92;
                letter-spacing: normal;
                text-align: left;
                color: #447ff4;

                &-Month {
                    font-size: 16px;
                    font-weight: normal;
                    font-family: "Nunito", sans-serif;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0.92;
                    letter-spacing: normal;
                    text-align: left;
                    color: #447ff4;
                }
            }


        }
    }

    &-PaymentMethods {
        margin-top: 50px;
        height: 179px;
        width: 32%;
        background-color: #ffffff;
        border-radius: 10px;
        border: solid 1px rgba(168, 170, 188, 0.2);

        &-Image {
            width: 28.6px;
            height: 28.7px;
            margin: 0 3.4px 16px 0;
            object-fit: contain;
            padding-left: 16px;
            padding-top: 17px;
        }

        &-Title {
            width: 136px;
            height: 22px;
            margin-left: 16px;
            font-family: "Nunito" sans-serif;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: left;
            color: #434655;
        }

        &-CreditCard {
            display: flex;
            align-items: center;
            color: #a8aabc;
            margin: 16px 8px 8px 16px;


            &-Icon {
                height: 24px;
                width: 24px;
            }

            &-Text {
                margin-left: 8px;
            }
        }
    }

}