.PaymentRequests {


    &-Button {

        &-PastPayments {
            margin: 20px;
            display: flex;
            justify-content: end;
            text-decoration: none;

            &-Style {

                width: 239px;
                height: 50px;
                background-color: #ffffff;
                border-radius: 7px;
                border: none;
                align-items: center;
                display: flex;
                padding-left: 25px;
                text-decoration: none;



                &-Icon {}

                &-Text {
                    height: 24px;
                    margin-left: 10px;
                    font-family: "Roboto", sans-serif;
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    text-align: center;
                    color: #023358;
                    text-decoration: none;

                }



            }
        }


        &-PaymentTeacher {

            display: grid;

            &-PaymentCompleted {
                width: 130px;
                height: 45px;
                border-radius: 7px;
                background-color: #45bf55;
                border: none;
                margin: 3px;
            }

            &-SeeTheTeacher {
                width: 130px;
                height: 45px;
                border-radius: 7px;
                background-color: #0066ff;
                border: none;
                margin: 3px;
            }

            &-Text {
                height: 21px;
                font-family: "Roboto", sans-serif;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.31;
                letter-spacing: normal;
                text-align: center;
                color: #fafafa;
            }

        }
    }

    .ant-table-cell {
        background-color: #f9fafa !important;
        align-items: center !important;
        text-align: center !important;
        vertical-align: middle !important;
        background-color: transparent !important;
        font-family: "Nunito", sans-serif !important;
        font-size: 18px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.35 !important;
        letter-spacing: normal !important;
        color: #434655 !important;
    }

    .ant-table-row {
        background-color: #f9fafa !important;
        height: 11px !important;
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #434655 !important;
    }



    .CustomPlaceholder .ant-select-selection-item {
        font-family: "Nunito", sans-serif;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #a8aabc;
    }

    .CustomPlaceholder::placeholder {
        text-align: center;
        color: #434655;
    }

    .ant-table-thead {
        ::before {
            background-color: transparent !important;

        }

        background-color: #f9fafa !important
    }



    .ant-table-tbody {
        tr {
            td {
                border-bottom: 0px !important;
                color: #434655 !important;
                font-size: 18px !important;
                font-weight: normal !important;

            }
        }
    }

}