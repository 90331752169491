.InsHeader {
    position: fixed;
    width: calc(100% - 340px);
    height: 71px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    z-index: 9999;


    &-Text {
        font-family: "Nunito", sans-serif;
        font-weight: 900;
        font-size: 24px;
        line-height: 32px;
        color: #434655;
    }

    &-Right {
        display: flex;
        justify-content: start;
        align-items: center;


        &-Button {
            background-color: #f5f7f9;
            padding: 13px;
            border: none;
            border-radius: 10px;
            text-decoration: none;
            margin: 0 12px;
            cursor: pointer;

            &-icon {
                color: #a8aabc;
                width: 20px;
                height: 20px;
                font-size: 20px;
            }
        }

        &-UpgradePlan {
            font-family: "Nunito", sans-serif;
            font-weight: 600;
            color: #ffffff;
            font-size: 16px;
            line-height: 22px;
            background-color: #447ff4;
            padding: 13px 40px;
            border-radius: 15px;
            text-decoration: none;
            margin-left: 12px;

        }
    }
}