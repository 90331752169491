.ReportQuestions {

    &-All {
        display: flex;
        justify-content: space-between;
        padding-right: 65px;
        margin-bottom: 20px;

        &-Button {
            width: 130px;
            height: 45px;
            border-radius: 7px;
            background-color: #447ff4;
            text-decoration: none;
            border: none;

            &-Text {
                font-family: "Roboto", sans-serif;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: normal;
                text-align: left;
                color: #fafafa;
            }


        }
    }

    &-QuestionPhoto {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        border: solid 1px #afafaf;
    }

    &-Button {
        display: grid;
        align-items: center;
        justify-content: center;

        &-SeeQuestion {
            width: 130px;
            height: 45px;
            border-radius: 7px;
            background-color: #45bf55;
            text-decoration: none;
            border: none;
            margin-bottom: 5px;
        }

        &-Delete {
            width: 130px;
            height: 45px;
            border-radius: 7px;
            background-color: #ff1818;
            text-decoration: none;
            border: none;

        }

        &-Text {
            font-family: "Nunito", sans-serif;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
        }
    }


}

.ant-table-cell {
    align-items: center !important;
    text-align: center !important;
    vertical-align: middle !important;
    background-color: transparent !important;
}

.ant-table-row {
    background-color: #f9fafa !important;
}


.ant-pagination-item {
    a {
        color: #447ff4 !important;
    }

    &-active {
        background-color: #447ff4 !important;

        a {
            color: #ffffff !important;
        }
    }
}



.ant-pagination-item-link {
    svg {
        color: #447ff4;
    }
}

.ant-table-thead {
    background-color: #f9fafa !important;

    ::before {
        background-color: transparent !important;
    }
}

.ant-table-tbody {
    tr {
        td {
            border-bottom: 0px !important;


        }
    }
}