.TeachersDetails {

    &-FirstArea {

        display: flex;
        justify-content: space-between;
        align-items: center;

        &-PersonalInformation {
            width: 500px;
            height: 273px;
            padding: 34px 0px 29px 32px;
            border-radius: 24px;
            background-color: #ffffff;
            align-items: center;
            display: flex;
            flex-wrap: wrap;

            &-Title {
                height: 27px;
                margin: 0 0px 9px 3px;
                font-family: "Nunito", sans-serif;
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.35;
                letter-spacing: normal;
                text-align: left;
                color: #434655;
            }

            &-Text {
                height: 24px;
                margin: 8px 0px 15px 3px;
                font-family: "Nunito", sans-serif;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                color: #434655;
                margin-right: 5px;
            }
        }

        &-Balance {

            margin-top: 50px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            padding: 0px 10px;

            &-Title {
                height: 24px;
                font-family: "Nunito", sans-serif;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: center;
                color: #023358;
                margin-bottom: 16px;
            }

            &-Input {
                width: 191px;
                height: 48px;
                border-radius: 10px;
                border: solid 2px #023358;
                background-color: #fff;
                margin-bottom: 10px;
            }

            &-Button {
                &-Add {

                    &-Style {
                        width: 191px;
                        height: 48px;
                        border-radius: 10px;
                        background-color: #45bf55;
                        border: none;
                        margin: 5px 0px;
                    }

                    &-Text {
                        height: 21px;
                        font-family: "Roboto", sans-serif;
                        font-size: 16px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.31;
                        letter-spacing: normal;
                        text-align: center;
                        color: #fafafa;

                    }
                }

                &-Delete {
                    &-Style {
                        width: 191px;
                        height: 48px;
                        border-radius: 10px;
                        background-color: #ff0000;
                        border: none;
                        margin: 5px 0px;
                    }

                    &-Text {
                        height: 21px;
                        font-family: "Roboto", sans-serif;
                        font-size: 16px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.31;
                        letter-spacing: normal;
                        text-align: center;
                        color: #fafafa;
                    }
                }
            }
        }


        &-Report {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            padding: 0px 20px;

            &-ReportedQuestions {
                width: 200px;
                height: 60px;
                border-radius: 10px;
                background-color: #0066ff;
                border: none;
                margin: 5px 0px;


                &-Text {
                    height: 24px;
                    font-family: "Roboto", sans-serif;
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fafafa;
                }
            }


            &-ReportedSolutions {
                width: 200px;
                height: 60px;
                border-radius: 10px;
                background-color: #0066ff;
                border: none;
                margin: 5px 0px;

                &-Text {
                    height: 24px;
                    font-family: "Roboto", sans-serif;
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fafafa;
                }
            }

        }

        &-BlockDelete {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            padding: 0px 30px;

            &-Block {
                width: 260px;
                height: 70px;
                border-radius: 15px;
                background-color: #ff9200;
                border: none;
                margin: 5px 0px;

                &-Text {
                    height: 26px;
                    font-family: "Roboto", sans-serif;
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.3;
                    letter-spacing: normal;
                    text-align: left;
                    color: #fafafa;
                }

                &-Icon {
                    width: 25px;
                    height: 25px;
                    margin: 1px 37px 0 0;
                    color: #fafafa;
                }
            }

            &-Delete {
                width: 260px;
                height: 70px;
                border-radius: 15px;
                background-color: #ff1818;
                border: none;
                margin: 5px 0px;


                &-Text {
                    height: 26px;
                    font-family: "Roboto", sans-serif;
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.3;
                    letter-spacing: normal;
                    text-align: left;
                    color: #fafafa;
                }

                &-Icon {

                    width: 25px;
                    height: 25px;
                    margin: 1px 37px 0 0;
                    color: #fafafa;
                }
            }

        }

    }

    &-SecondArea {
        display: flex;
        justify-content: space-between;

        &-Left {

            &-ButtonArea {
                &-Select {
                    width: 200px;
                    height: 60px;
                    border-radius: 10px;
                    border: solid 2px #023358;
                    background-color: #ffffff;
                    margin-right: 20px;
                }

                &-Delete {
                    width: 200px;
                    height: 60px;
                    background-color: #f00000;
                    border: none;
                    border-radius: 10px;
                    margin: 10px 20px;
                }

                &-Add {
                    width: 200px;
                    height: 60px;
                    background-color: #45bf55;
                    border: none;
                    border-radius: 10px;
                    margin: 10px 20px;
                }

                &-ButtonText {
                    height: 24px;
                    font-family: "Roboto", sans-serif;
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fafafa;
                }
            }

            &-Payments {
                height: 356px;
                border-radius: 24px;

                &-Title {
                    height: 27px;
                    margin: 0 17px 19px 0;
                    font-family: "Nunito", sans-serif;
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.35;
                    letter-spacing: normal;
                    text-align: left;
                    color: #434655;
                }
            }

            &-WithdrawalRequest {
                height: 97px;
                border-radius: 24px;
                background-color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0px 5px;

                &-Title {
                    height: 22px;
                    margin: 0 10px 0 0;
                    font-family: "Nunito", sans-serif;
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    text-align: left;
                    color: #434655;
                }

                &-Text {
                    height: 15px;
                    font-family: "Nunito", sans-serif;
                    font-size: 11px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.36;
                    letter-spacing: normal;
                    text-align: left;
                    color: #a8aabc;

                }
            }
        }

        &-Right {


            &-Stats {
                min-width: 300px;
                height: 526px;
                padding: 30px 50px 18px 30px;
                border-radius: 24px;
                background-color: #ffffff;

                &-TitleSelect {

                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    &-Title {
                        height: 27px;
                        margin: 8.5px 70px 9px 4px;
                        font-family: "Nunito", sans-serif;
                        font-size: 20px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.35;
                        letter-spacing: normal;
                        text-align: left;
                        color: #434655;
                    }

                    &-IconSelect {
                        display: flex;
                        align-items: center;

                        &-Icon {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    &-Select {
                        width: 95px;
                        height: 28px;
                        border-radius: 7px;
                        opacity: 0.5;
                        background-color: #a8aabc;

                    }
                }


                &-Area {
                    display: flex;
                    justify-content: space-between;

                    &-Left {
                        flex-wrap: wrap;

                        &-Text {
                            height: 22px;
                            font-family: Nunito;
                            font-size: 16px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.38;
                            letter-spacing: normal;
                            text-align: left;
                            color: #434655;
                        }
                    }

                    &-Right {
                        flex-wrap: wrap;

                        &-Text {
                            height: 22px;
                            font-family: "Nunito", sans-serif;
                            font-size: 16px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.38;
                            letter-spacing: normal;
                            text-align: right;
                            color: #434655;
                        }
                    }
                }
            }

        }

    }



    .ant-form-item {
        margin: 0px !important;
    }

    .ant-table-cell {
        align-items: center !important;
        text-align: center !important;
        vertical-align: middle !important;
        background-color: transparent !important;
        font-family: "Nunito", sans-serif !important;
        font-size: 18px !important;
        font-weight: normal !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.35 !important;
        letter-spacing: normal !important;
        color: #434655 !important;
    }

    .ant-table-row {
        background-color: #ffffff !important;
        height: 11px !important;
        font-family: "Nunito", sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #a8aabc !important;
    }



    .CustomPlaceholder .ant-select-selection-item {
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #434655;
    }

    .CustomPlaceholder::placeholder {
        text-align: center;
        color: #434655;
    }

    .ant-table-thead {
        ::before {
            background-color: transparent !important;

        }
    }

    .ant-input {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        text-align: center;
        color: #023358;
    }

    .ant-table-tbody {
        tr {
            td {
                border-bottom: 0px !important;
                color: #a8aabc !important;
                font-size: 16px !important;

            }
        }
    }


}