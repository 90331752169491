.Settings {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-LeftArea {
        background-color: #ffffff;
        width: 528px;
        height: 598px;
        margin: 8px;


        &-SchoolLogo {
            height: 100px;
            width: 98px;
            margin: 32px 215px 8px 215px;

            &-Icon {
                width: 24px;
                height: 24px;
                margin: 0px 209px 6px 250px;
                padding: 4.2px;
                background-color: #447ff4;
                border-radius: 100px;
                color: #ffffff;
            }
        }

        &-SchoolName {
            color: #434655;
            width: 130px;
            height: 19px;
            font-family: "Nunito", sans-serif;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.36;
            letter-spacing: normal;
            text-align: center;
            margin: 0px 199px 0 199px;
        }

        &-InputBody {
            width: 238px;
            height: 40px;
            border-radius: 7.5px;
            border: solid 1px rgba(168, 170, 188, 0.2);
            background-color: rgba(255, 255, 255, 0);
            padding: 9px 7px;
            margin: 16px 16px 0 16px;

            &-Adress {
                width: 490px;
                height: 40px;
                border-radius: 7.5px;
                border: solid 1px rgba(168, 170, 188, 0.2);
                background-color: rgba(255, 255, 255, 0);
                padding: 9px 7px;
                margin: 16px 16px 0 16px;

            }


            &-Text {
                width: 90px;
                height: 15px;
                margin: 0 59px 0px 0;
                font-family: "Nunito", sans-serif;
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: normal;
                text-align: left;
                color: #a8aabc;
            }

            &-Input {
                width: 215px;
                height: 30px;
                font-family: "Nunito", sans-serif;
                font-size: 13px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: normal;
                text-align: left;
                color: #434655;
                border: solid 0px;
                padding: 0;
                margin-top: 5px;

                &-Adress {
                    height: 30px;
                    font-family: "Nunito", sans-serif;
                    font-size: 13px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    text-align: left;
                    color: #434655;
                    border: solid 0px;
                    padding: 0;
                    width: 476px;
                }

            }

        }
    }

    &-RightArea {
        background-color: #ffffff;
        width: 528px;
        height: 598px;
        margin: 8px;



        &-NotificationLogo {
            width: 60px;
            height: 64px;
            color: #447ff4;
            background-color: #ffffff;
            margin: 48px 234px 0 215px;

            &-Icon {
                width: 36.5px;
                height: 36.4px;
                color: #447ff4;
                background-color: #ffffff;
                padding: 3px;
                border-radius: 100px;
            }

            &-Text {
                text-align: center;
                width: 120px;
                height: 19px;
                font-family: "Nunito", sans-serif;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: normal;
                color: #434655;
                margin: 6px 204px 2px 204px;

                &-Explanation {
                    width: 300px;
                    height: 14px;
                    color: #a8aabc;
                    font-size: 10px;
                    font-family: "Nunito", sans-serif;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.7;
                    letter-spacing: normal;
                    text-align: center;
                    margin: 0 114px;
                }
            }
        }

        &-FormText {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-NotificationTitle {
                height: 15px;
                font-family: "Nunito", sans-serif;
                font-size: 11px;
                color: #434655;
                text-align: left;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.73;
                letter-spacing: normal;
                margin: 28px 16px 18px 16px;

                &-Input {
                    width: 365px;
                    height: 33.4px;
                    border: solid 1px rgba(168, 170, 188, 0.2);
                    margin-left: 12px;
                }

                &-Explanation {
                    width: 110px;
                    height: 9px;
                    margin: 0px 14px 0px 140px;
                    font-family: "Nunito", sans-serif;
                    font-size: 7px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.43;
                    letter-spacing: normal;
                    text-align: left;
                    color: #a8aabc;
                }
            }


            &-NotificationContent {
                &-Input {
                    width: 365px;
                    height: 61px;
                    border: solid 1px rgba(168, 170, 188, 0.2);
                    margin-left: 12px;
                    resize: none;
                    overflow-y: hidden;
                }

                &-Explanation {
                    width: 110px;
                    height: 9px;
                    margin: 20px 17px 0px 140px;
                    font-family: "Nunito", sans-serif;
                    font-size: 7px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.43;
                    letter-spacing: normal;
                    text-align: left;
                    color: #a8aabc;
                }
            }

            &-NotificationPicture {
                width: 340px;
                height: 35.3px;
                border: solid 1px rgba(168, 170, 188, 0.2);
                margin-left: 15px;

                &-Button {
                    height: 34.2px;

                }

                &-Explanation {
                    width: 110px;
                    height: 9px;
                    margin: 0px 17px 9px 140px;
                    font-family: "Nunito", sans-serif;
                    font-size: 7px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.43;
                    letter-spacing: normal;
                    text-align: left;
                    color: #a8aabc;
                }

            }


            &-Date {
                width: 230px;
                height: 33.4px;
                padding: 7.2px 4px 7.2px 2px;
                border: solid 1px rgba(168, 170, 188, 0.2);
                background-color: #ffffff;

                &-Or {

                    width: 30px;
                    height: 11px;
                    margin: 52px 10px 15px 10px;
                    font-family: "Nunito", sans-serif;
                    font-size: 8px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.38;
                    letter-spacing: normal;
                    text-align: left;
                    color: #a8aabc;
                }



                &-Explanation {
                    width: 110px;
                    height: 9px;
                    margin: 2px 17px 0px 140px;
                    font-family: "Nunito", sans-serif;
                    font-size: 8px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.38;
                    letter-spacing: normal;
                    text-align: left;
                    color: #a8aabc;
                }

            }

            &-DateButton {


                &-Style {
                    width: 101px;
                    height: 35px;
                    border-radius: 10px;
                    margin-left: 65px;
                    border-color: transparent;
                    background-color: #447ff4;
                    color: #ffffff;
                    font-size: 8px;
                    font-family: "Nunito", sans-serif;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.38;
                    letter-spacing: normal;
                    text-decoration: none;

                }
            }

            &-SentButton {
                width: 101px;
                height: 34px;
                border-radius: 10px;
                margin-left: 393px;
                border-color: transparent;
                background-color: #447ff4;
                color: #ffffff;
                font-size: 8px;
                font-family: "Nunito", sans-serif;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.38;
                letter-spacing: normal;
                text-decoration: none;

            }

        }


    }
}

.logo-container {
    position: relative;
}

.camera-icon-container {
    position: absolute;
    top: 108px;
    left: 32px;
}


.notificationlogo-container {
    position: relative;
    transform: rotate(-25deg);
    transform-origin: center;
}

.tick-icon-container {
    position: absolute;
    top: 50px;
    left: 250px;
    transform: rotate(-25deg);
    transform-origin: center;
}