.StudentList {
    .ant-table-thead {
        tr {
            th {
                background-color: transparent !important;
                text-align: center !important;
                font-family: "Nunito", sans-serif !important;
                font-size: 14px !important;
                font-stretch: normal !important;
                font-style: normal !important;
                line-height: 1.36 !important;
                letter-spacing: normal !important;
                color: #434655 !important;
                height: 40px !important;
                vertical-align: bottom !important;
            }
        }

    }

    .ant-table-column-sorters {
        display: block !important;
    }

    .ant-table-column-sorter-full {
        color: #000000 !important;

    }

    .ant-table-column-title {
        font-size: 13px !important;
        color: #000000 !important;
    }

    .ant-table-cell {
        padding: 0 !important;
        height: 60px !important;
        border-bottom: none !important;
    }

    &-Image {
        height: 32px;
        width: 32px;
        border-radius: 5px;
        margin-right: 8px;
    }

    &-Name {
        text-align: center !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;
    }

    &-Data {
        vertical-align: middle;
        text-align: center;
    }

    &-detailButton {
        width: 135px;
        height: 39px;
        padding: 9.2px 47.7px 9.8px 46.3px;
        border-radius: 10px;
        background-color: #447ff4;
        color: #ffffff;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        font-family: "Nunito", sans-serif;
    }




    .ant-pagination-item {
        background-color: #447ff4;
        min-width: 16px;
        width: 16px;
        min-height: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #ffffff;
        }
    }

    .ant-pagination {
        .anticon {
            width: 16px;
            height: 16px;
            border-radius: 3px;
            background-color: rgba(68, 127, 244, 0.2);
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 15px;
                height: 15px;
                background-color: rgba(68, 127, 244, 0.2);
                color: #ffffff;
            }
        }

        &-prev,
        &-next {
            min-height: 16px;
            min-width: 16px;
            width: 16px;
            height: 16px;

        }

    }

    .ant-table-row {
        background-color: transparent !important;
    }

}